import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import {until} from 'lit/directives/until';
import '@iconify/iconify';

const COVID_API_HH = 'https://api.corona-zahlen.org/states/HH';

@customElement('covid-incidence')
export class CovidIncidence extends LitElement {
  render() {
    return html`
      <div class="flex p-2 space-x-4 place-content-center object-center		">
        <div class="">
          <i class="h-6 w-6 iconify" data-icon="mdi:virus"></i>
        </div>
        <div class="text-xl">${until(this.getCurrentIncidence(), html`?`)}</div>
      </div>
    `;
  }

  createRenderRoot() {
    return this;
  }

  async getCurrentIncidence() {
    let res = await fetch(COVID_API_HH).then((res) => res.json());
    return Math.round(res.data.HH.weekIncidence);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'covid-incidence': CovidIncidence;
  }
}
